<template>
	<div class="text-center pl-3 pr-3 pt-5">
		<v-btn
			class="mt-2 rounded-l cta"
			large
			block
			color="#FED833"
			@click="emitAction"
			v-if="false"
		>
			Save & Continue </v-btn
		><v-btn
			class="mt-2 rounded-l cta"
			large
			block
			color="#EBECF0"
			@click="PrevPage"
			:disabled="formData.currentStep <= 1"
			v-if="false"
		>
			Back
		</v-btn>

		<v-container class="ma-0 pa-0" v-if="formData.currentStep == 13"
			><v-row no-gutters>
				<v-btn
					class="mt-2 rounded-l cta"
					large
					color="#13C11F"
					block
					dark
					@click="emitAction"
				>
					Go to my solutions
				</v-btn>
			</v-row>
		</v-container>

		<v-container class="ma-0 pa-0" v-if="formData.currentStep == 14"
			><v-row>
				<v-col><v-btn @click="resetForm">Start over</v-btn> </v-col>
			</v-row></v-container
		>

		<v-container class="ma-0 pa-0" v-if="formData.currentStep < 13">
			<v-row no-gutters>
				<v-col
					v-show="formData.currentStep > 1"
					cols="5"
					class="pl-0 pr-2"
					><v-btn
						class="mt-2 rounded-l cta"
						large
						color="#EBECF0"
						block
						@click="PrevPage"
						:disabled="formData.currentStep <= 1"
					>
						Back
					</v-btn>
				</v-col>
				<v-col :cols="formData.currentStep != 1 ? 7 : 12" class="pr-0"
					><v-btn
						class="mt-2 rounded-l cta"
						block
						large
						color="#FED833"
						@click="emitAction"
					>
						Save & Continue
					</v-btn>
				</v-col>
			</v-row>
		</v-container>
	</div>
</template>
<script>
import { mapState } from "vuex";
export default {
	computed: {
		...mapState(["formData"]),
	},
	methods: {
		PrevPage() {
			window.scrollTo({
				top: 0,
				behavior: "smooth",
			});
			this.$store.dispatch("prevPage");
		},
		resetForm() {
			this.$store.dispatch("clearFormProgress");
			this.$router.push("/home");
		},
		emitAction() {
			this.$emit("vc");
		},
	},
};
</script>
